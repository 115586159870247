import React from 'react'
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
import { ThemeProvider } from 'styled-components'
import Navigation from './Navigation'
import UpdateSnackbar from './Components/UpdateSnackbar'
import breakpoints from './Theme/breakpoints'
import typography, { fontWeight } from './Theme/typography'
import palette from './Theme/palette'
import globals from './Theme/globals'
import zIndex from './Theme/zIndex'
import mixins from './Theme/mixins'
import spacing from './Theme/spacing'
import overrides from './Theme/overrides'

const theme = createMuiTheme({
  typography: {
    ...typography,
    useNextVariants: true,
  },
  fontWeight,
  palette,
  globals,
  zIndex,
  mixins,
  spacing,
  breakpoints: { values: breakpoints },
  overrides,
  props: {
    MuiButtonBase: {
      disableRipple: true,
    },
  },
})

const App = () => (
  <MuiThemeProvider theme={theme}>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Navigation />
      <UpdateSnackbar />
    </ThemeProvider>
  </MuiThemeProvider>
)

export default App
