import { combineReducers } from 'redux'
import { firebaseReducer } from 'react-redux-firebase'
import { firestoreReducer } from 'redux-firestore'
import { connectRouter } from 'connected-react-router'

const rootReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    firebase: firebaseReducer,
    firestore: firestoreReducer,
    // account: require('./AccountRedux').reducer,
    app: require('./AppRedux').reducer,
  })

export default rootReducer
