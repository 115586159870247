import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import Button from '@material-ui/core/Button'
import Snackbar from '@material-ui/core/Snackbar'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import { withStyles } from '@material-ui/core/styles'

const styles = (theme) => ({
  close: {
    padding: theme.spacing.unit / 2,
  },
})

const UpdateSnackbar = ({ classes }) => {
  const updateAvailable = useSelector(({ app }) => app.updateAvailable)
  const [show, setShow] = useState(true)

  const handleRefresh = () => {
    // Grab Service Worker from Global Scope
    if (window.myServiceWorker && window.myServiceWorker.waiting) {
      // Tell Service Worker not to wait for all tabs to close.
      window.myServiceWorker.waiting.postMessage({ action: 'skipWaiting' })
    }
    // Reload page.
    window.location.reload(true)
  }

  const handleClose = () => setShow(false)

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      open={show && updateAvailable}
      onClose={handleClose}
      ContentProps={{
        'aria-describedby': 'message-id',
      }}
      message={<span id="message-id">App update available. Close all tabs, or click refresh.</span>}
      action={[
        <Button key="undo" color="secondary" size="small" onClick={handleRefresh}>
          REFRESH
        </Button>,
        <IconButton key="close" aria-label="Close" color="inherit" className={classes.close} onClick={handleClose}>
          <CloseIcon />
        </IconButton>,
      ]}
    />
  )
}

export default withStyles(styles)(UpdateSnackbar)
