import { createStore, applyMiddleware, compose } from 'redux'
import { routerMiddleware } from 'connected-react-router'
import { createBrowserHistory } from 'history'
import 'firebase/auth'
// import 'firebase/database';
import 'firebase/storage'
import 'firebase/firestore' // make sure you add this for firestore

// import { reduxFirestore } from "redux-firestore";

// import { reactReduxFirebase } from "react-redux-firebase";

import createSagaMiddleware from 'redux-saga'

// import FIREBASE_CONFIG from "../Config/firebase.json";
// import { USER_PROFILE } from "../Config";

// import AccountActions from "../Redux/AccountRedux";
// import AssembleActions from "../Redux/AssembleRedux";

import createRootReducer from './index'
import rootSaga from '../Sagas'

export const history = createBrowserHistory()

const CreateStore = () => {
  // Create a history depending on the environment
  // const history = createBrowserHistory();

  // // initialize firebase instance once
  // if (!firebase.apps.length) {
  //   console.log("initilizing firebase");
  //   firebase.initializeApp(FIREBASE_CONFIG);
  //   // Initialize Firestore with timeshot settings
  //   // firebase.firestore().settings({ timestampsInSnapshots: true });
  //   // firebase.firestore().enablePersistence();
  //   // firebase.firestore.setLogLevel('debug');
  // }

  // const reactReduxEnhancer = reactReduxFirebase(firebase, {
  //   userProfile: USER_PROFILE,
  //   useFirestoreForProfile: true, // Store in Firestore instead of Real Time DB
  //   onAuthStateChanged: async (auth, firebase, dispatch) => {
  //     if (!auth) {
  //       console.log("onAuthStateChanged: logged out");
  //       dispatch(AccountActions.AccountSetClaims(""));
  //       return;
  //     }
  //     const user = firebase.auth().currentUser;
  //     console.log("onAuthStateChanged: logged in ", user);
  //     console.log("LOG getIdTokenResult starting");
  //     const idTokenResult = await user.getIdTokenResult();
  //     console.log("LOG getIdTokenResult finished", idTokenResult);
  //     dispatch(AccountActions.AccountSetClaims(idTokenResult.claims));
  //     // dispatch({ type: 'CLAIMS_UPDATE', payload: idTokenResult }) // some custom action
  //   },
  // });

  const enhancers = []
  // const enhancers = [reactReduxEnhancer, reduxFirestore(firebase)];

  // Dev tools are helpful
  // if (process.env.NODE_ENV === 'development') {
  const { devToolsExtension } = window

  if (typeof devToolsExtension === 'function') {
    enhancers.push(devToolsExtension())
  }
  // }

  /* ------------- Saga Middleware ------------- */
  const sagaMiddleware = createSagaMiddleware()

  const middleware = [sagaMiddleware, routerMiddleware(history)]

  const composedEnhancers = compose(applyMiddleware(...middleware), ...enhancers)

  // Do we have preloaded state available? Great, save it.
  const initialState = { firebase: { authError: null } }

  // Create the store
  const store = createStore(
    // connectRouter(history)(rootReducer),
    createRootReducer(history),
    // rootReducer,
    initialState,
    composedEnhancers,
  )

  if (process.env.NODE_ENV !== 'production') {
    if (module.hot) {
      // Enable Webpack hot module replacement for reducers
      module.hot.accept('./index', () => {
        const nextRootReducer = require('.').default
        store.replaceReducer(nextRootReducer)
      })
    }
  }

  // kick off root saga
  sagaMiddleware.run(rootSaga)

  return {
    store,
    history,
  }
}

export default CreateStore
