// a library to wrap and simplify api calls
import apisauce from 'apisauce'
import { API_BASE_URL } from '../Config'
// our "constructor"
const create = (baseURL = API_BASE_URL) => {
  // ------
  // STEP 1
  // ------
  //
  // Create and configure an apisauce-based api object.
  //
  const api = apisauce.create({
    // base URL is read from the "constructor"
    baseURL,
    // here are some default headers
    headers: {
      // 'Content-Type': 'application/json',
      // "Access-Control-Allow-Origin" : "*", // Required for CORS support to work
      // "Access-Control-Allow-Credentials" : true // Required for cookies, authorization headers with HTTPS
      // 'Cache-Control': 'no-cache'
    },
    // 3 minute timeout
    timeout: 1000 * 60 * 3,
  })

  // ------
  // STEP 2
  // ------
  //
  // Define some functions that call the api.  The goal is to provide
  // a thin wrapper of the api layer providing nicer feeling functions
  // rather than "get", "post" and friends.
  //
  // I generally don't like wrapping the output at this level because
  // sometimes specific actions need to be take on `403` or `401`, etc.
  //
  // Since we can't hide from that, we embrace it by getting out of the
  // way at this level.
  //

  const status = () => api.get('status')

  const getToken = (access_token) => api.post('token', { access_token })

  const getUserInfo = (id_token) => {
    api.setHeader('Authorization', `Bearer ${id_token}`)
    return api.get('userinfo')
  }

  const applyPin = (pin, token, scope) => api.post('pin', { pin, token, scope })

  const signup = (email, password, pin) => api.post('signup', { email, password, pin })

  const sendNotification = (payload, token) => api.post('message', { payload, token })

  // ------
  // STEP 3
  // ------
  //
  // Return back a collection of functions that we would consider our
  // interface.  Most of the time it'll be just the list of all the
  // methods in step 2.
  //
  // Notice we're not returning back the `api` created in step 1?  That's
  // because it is scoped privately.  This is one way to create truly
  // private scoped goodies in JavaScript.
  //
  return {
    // a list of the API functions from step 2
    status,
    getToken,
    getUserInfo,
    applyPin,
    signup,
    sendNotification,
  }
}

// let's return back our create method as the default.

const api = {
  create,
}

export default api
