import React from 'react'
import { render } from 'react-dom'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import './index.css'
import { ReactReduxFirebaseProvider } from 'react-redux-firebase'
import { createFirestoreInstance } from 'redux-firestore'
import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/storage'
import 'firebase/firestore' // make sure you add this for firestore
import App from './App'
import createStore from './Redux/CreateStore'
import * as serviceWorker from './serviceWorker'

// Create a store and get back itself and its history object
const { store } = createStore()

serviceWorker.unregister()

const rootEl = document.getElementById('root')

// initialize firebase instance once
if (!firebase.apps.length) {
  firebase.initializeApp(require('./Config/firebase.json'))
  // Initialize Firestore with timeshot settings
  // firebase.firestore().settings({ timestampsInSnapshots: true });
  // firebase.firestore().enablePersistence();
  // firebase.firestore.setLogLevel('debug');
}

const rrfProps = {
  firebase,
  config: {
    // userProfile: USER_PROFILE,
    useFirestoreForProfile: true, // Store in Firestore instead of Real Time DB
    onAuthStateChanged: async () => {
      // if (!auth) {
      //   console.log("onAuthStateChanged: logged out");
      //   dispatch(AccountActions.AccountSetClaims(""));
      //   return;
      // }
      // const user = firebase.auth().currentUser;
      // console.log("onAuthStateChanged: logged in ", user);
      // console.log("LOG getIdTokenResult starting");
      // const idTokenResult = await user.getIdTokenResult();
      // console.log("LOG getIdTokenResult finished", idTokenResult);
      // dispatch(AccountActions.AccountSetClaims(idTokenResult.claims));
      // dispatch({ type: 'CLAIMS_UPDATE', payload: idTokenResult }) // some custom action
    },
  },
  dispatch: store.dispatch,
  createFirestoreInstance,
}

const Application = (Component) => (
  <Provider store={store}>
    <BrowserRouter>
      {/* <ConnectedRouter history={history}> */}
      <ReactReduxFirebaseProvider {...rrfProps}>
        <Component />
      </ReactReduxFirebaseProvider>
      {/* </ConnectedRouter> */}
    </BrowserRouter>
  </Provider>
)

render(Application(App), rootEl)

if (module.hot) {
  module.hot.accept('./App', () => {
    const NextApp = require('./App').default
    render(Application(NextApp), rootEl)
  })
}
