import React from 'react'
import { Route, Switch } from 'react-router-dom'
import loadable from '@loadable/component'
import Routes from '../enums/Routes'

/** Import Each Page Component On-Demand * */
const AsyncHome = loadable(() => import('../Containers/Home'))
const AsyncLogin = loadable(() => import('../Containers/Login'))
const AsyncPin = loadable(() => import('../Containers/Pin'))
const AsyncError = loadable(() => import('../Containers/Error'))

const Navigation = () => (
  <Switch>
    <Route exact path={Routes.HOME} component={AsyncHome} />
    <Route exact path={Routes.LOGIN} component={AsyncLogin} />
    <Route exact path={Routes.LOGIN_WITH_PARAMS} component={AsyncLogin} />
    <Route exact path={Routes.PIN_WITH_PARAMS} component={AsyncPin} />
    <Route path={Routes.ERROR} component={AsyncError} />
  </Switch>
)
export default Navigation
