import typography, { sourceSansPro } from './typography'
import breakpoints from './breakpoints'
import palette, { text } from './palette'
import globals from './globals'
import zIndex from './zIndex'
import mixins from './mixins'
import spacing from './spacing'
// import iconSearch from "../Icons/IconSearch.svg";

const overrides = {
  MuiCssBaseline: {
    '@global': {
      '@font-face': sourceSansPro,
      html: {
        overscrollBehavior: 'none',
      },
      body: {
        overscrollBehavior: 'none',
        backgroundColor: 'white',
      },
      '::-webkit-scrollbar': {
        width: '0 !important',
      },
    },
  },
  MuiAppBar: {
    root: {
      boxShadow: globals.boxShadowSmall,
    },
    colorDefault: {
      backgroundColor: palette.secondary.dark,
      color: palette.secondary.contrastText,
    },
  },
  MuiToolbar: {
    root: {
      minHeight: `${globals.headerHeight}px !important`,
      paddingLeft: `${spacing(1.5)}px !important`,
      paddingRight: `${spacing(1.5)}px !important`,
      // [`@media(min-width: ${breakpoints.md}px)`]: {
      //   paddingLeft: `${spacing(7)}px !important`,
      //   paddingRight: `${spacing(7)}px !important`,
      // }
    },
  },
  MuiTabs: {
    root: {
      paddingLeft: 0,
      paddingRight: 0,
      backgroundColor: palette.background.paper,
      display: 'flex',
      alignItems: 'flex-end',
      minHeight: '40px !important',
      '& > div': {
        ...mixins.container,
        '& > div': {
          marginLeft: spacing(1.5) * -1,
          marginRight: spacing(1.5) * -1,
        },
      },
      '&.configurator': {
        margin: '0 !important',
        '& > div': {
          padding: '0 !important',
        },
      },
      '&.full-width': {
        marginLeft: spacing(1.5) * -1,
        marginRight: spacing(1.5) * -1,
      },
      '&.shadow': {
        boxShadow: globals.boxShadowSmaller,
      },
    },
    indicator: {
      height: 3,
    },
  },
  MuiTab: {
    root: {
      fontSize: `${typography.h6.fontSize}px !important`,
      lineHeight: `${typography.h6.lineHeight} !important`,
      // fontWeight: `400 !important`,
      letterSpacing: 0,
      color: `${text.secondaryHueLightish} !important`,
      // color: `${palette.common.black} !important`,
      textTransform: 'none',
      minWidth: '1px !important',
      marginLeft: spacing(1.5),
      marginRight: spacing(1.5),
      opacity: '1 !important',
      minHeight: '40px !important',
      paddingLeft: 0,
      paddingRight: 0,
      '&$selected': {
        color: `${text.primaryHue} !important`,
        fontWeight: `${typography.h6.fontWeight} !important`,
        display: 'inline-flex !important',
      },
    },
    wrapper: {
      '& > span': {
        padding: 0,
      },
    },
  },
  MuiOutlinedInput: {
    root: {
      backgroundColor: palette.background.paper,
      '& ::placeholder': {
        fontStyle: 'italic',
      },
    },
    input: {
      padding: '12px 10px',
      borderRadius: globals.borderRadius,
    },
  },
  MuiSelect: {
    filled: {
      backgroundColor: `${palette.background.paper} !important`,
      boxShadow: globals.boxShadowSmaller,
      minWidth: 170,
      padding: '12px 15px !important',
    },
    icon: {
      right: 8,
    },
  },
  MuiMenu: {
    root: {
      marginTop: '10px',
    },
    paper: {
      boxShadow: globals.boxShadowSmallerYShift,
      position: 'relative',
    },
  },
  MuiList: {
    root: {
      padding: '0 !important',
      // marginTop: '10px',
    },
  },
  MuiMenuItem: {
    root: {
      ...typography.body2,
      padding: '10px 15px !important',
      height: 'auto',
      minHeight: 'auto',
      whiteSpace: 'normal',
      '&$selected': {
        display: 'none',
      },
      '&.menu-item--lg': {
        padding: `${spacing(1)}px ${spacing(2)}px !important`,
        '&:first-of-type': {
          marginTop: `${spacing(1)}px`,
        },
        '&:last-of-type': {
          marginBottom: `${spacing(1)}px`,
        },
      },
      '&.menu-item--primary:hover': {
        backgroundColor: 'rgba(225, 28, 142, .08)',
      },
      '&.menu-item--secondary:hover': {
        backgroundColor: 'rgba(3, 54, 145, .08)',
      },
      '&.menu-item--neutral': {
        backgroundColor: palette.common.gray.lightest,
        color: text.light,
      },
      // '&:hover': {
      //   backgroundColor: palette.background.paper
      // }
    },
  },
  MuiIconButton: {
    root: {
      backgroundColor: palette.common.gray.lighter,
      transition: globals.transition,
      color: `${palette.common.black} !important`,
      height: 42,
      width: 42,
      padding: 0,
      '&:hover': {
        backgroundColor: `${palette.primary.main} !important`,
        boxShadow: globals.boxShadowSmaller,
        color: `${palette.primary.contrastText} !important`,
      },
    },
    colorPrimary: {
      backgroundColor: palette.background.paper,
      border: globals.border,
      '&:hover': {
        backgroundColor: `${palette.primary.main} !important`,
        // boxShadow: 'none',
        color: `${palette.primary.contrastText} !important`,
      },
    },
  },
  MuiRadio: {
    root: {
      border: 'none !important',
      backgroundColor: 'transparent !important',
      color: `${palette.border.dark} !important`,
      transition: `${globals.transitionFast} !important`,
      '& *': {
        transition: `${globals.transitionFast} !important`,
      },
      '& svg': {
        ...mixins.circle(15),
      },
      '&$checked': {
        color: `${palette.primary.main} !important`,
        '& svg + svg': {
          color: `${palette.primary.main} !important`,
          transform: 'scale(1) !important',
        },
      },
      '&:hover': {
        backgroundColor: `transparent !important`,
        boxShadow: 'none',
        '& svg:first-of-type': {
          color: `currentColor !important`,
        },
      },
    },
    colorPrimary: {
      '&:hover': {
        color: `${palette.primary.main} !important`,
      },
    },
  },
  MuiFormGroup: {
    root: {
      '& label': {
        marginRight: 0,
        marginLeft: 0,
      },
      '& label > span': {
        ...mixins.flexCenter,
        padding: 0,
        '&:first-of-type': {
          height: '25px !important',
          width: '25px !important',
        },
      },
    },
  },
  MuiDivider: {
    root: {
      backgroundColor: palette.border.dark,
    },
    light: {
      backgroundColor: palette.border.main,
    },
  },
  MuiDialog: {
    root: {
      '&.round .MuiPaper-root': {
        borderRadius: 10,
      },
      '&.center': {
        display: 'grid',
        alignItems: 'center',
      },
    },
    paper: {
      margin: spacing(2),
    },
    paperWidthFalse: {
      maxWidth: 'calc(100% - 30px)',
      // width: '100%',
      // [`@media(min-width: ${breakpoints.md}px)`]: {
      //   width: 'auto',
      // },
    },
    paperWidthSm: {
      maxWidth: 515,
    },
    paperScrollPaper: {
      maxHeight: '100%',
    },
    scrollPaper: {
      alignItems: 'flex-start',
    },
  },
  MuiPopover: {
    root: {
      '&.remove-z': {
        zIndex: '0 !important',
      },
      '&.menu--add-shadow .MuiMenu-paper:before': {
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '8px',
        boxShadow: '0 2px 8px 0 rgba(0, 0, 0, 0.32)',
        zIndex: 1,
      },
      '&.menu--add-shadow .MuiMenu-paper': {
        borderRadius: `0 0 ${globals.borderRadius}px ${globals.borderRadius}px`,
        [`@media(min-width: ${breakpoints.md}px)`]: {
          marginTop: '-8px',
        },
      },
    },
    paper: {
      overflowX: 'visible',
      overflowY: 'hidden',
    },
  },
  MuiSnackbar: {
    root: {
      zIndex: zIndex.tertiary,
    },
  },
  MuiTypography: {
    root: {
      color: text.primary,
    },
  },
  MuiCollapse: {
    hidden: {
      visibility: 'visible',
    },
  },
  MuiTooltip: {
    popper: {
      zIndex: zIndex.tertiary,
      pointerEvents: 'auto',
      '&.closed': {
        pointerEvents: 'none',
      },
    },
    tooltip: {
      '&.public': {
        padding: '20px 50px',
        backgroundColor: palette.common.white,
        color: palette.common.black,
        textAlign: 'center',
        borderRadius: 10,
        boxShadow: '0 2px 20px 0 rgba(0, 0, 0, .8)',
        '& .MuiTooltip-arrow::before': {
          color: 'transparent',
          // color: palette.common.white,
        },
        '&.dark': {
          backgroundColor: '#4A4A4A',
          color: palette.common.white,
          '& .MuiTooltip-arrow::before': {
            // color: '#4A4A4A',
            borderColor: `#4A4A4A !important`,
          },
        },
        '& .MuiTooltip-arrow': {
          width: '20px',
          height: '10px',
          backgroundColor: 'transparent',
          color: palette.common.white,
          '&::before': {
            borderColor: `${palette.common.white} !important`,
          },
        },
        '&.MuiTooltip-tooltipPlacementTop .MuiTooltip-arrow::before': {
          transform: 'translateX(0px)',
          borderLeft: '10px solid transparent !important',
          borderRight: '10px solid transparent !important',
          borderTop: '10px solid currentColor',
          // borderWidth: '1.75em 1.75em 0 1.75em',
          // transform: 'translateX(-.5em)',
        },
        '&.MuiTooltip-tooltipPlacementBottom .MuiTooltip-arrow::before': {
          transform: 'translateX(0px)',
          borderLeft: '10px solid transparent !important',
          borderRight: '10px solid transparent !important',
          borderBottom: '10px solid currentColor',
          // borderWidth: '0 1.75em 1.75em 1.75em',
          // transform: 'translateX(-.5em)',
        },
        '&.MuiTooltip-tooltipPlacementLeft .MuiTooltip-arrow': {
          width: '10px',
          height: '20px',
          left: '100%',
          top: 0,
          bottom: 0,
          margin: 'auto',
          '&::before': {
            transform: 'translateY(0px)',
            borderLeft: '10px solid currentColor',
            borderBottom: '10px solid transparent !important',
            borderTop: '10px solid transparent !important',
            // borderWidth: '1.75em 0 1.75em 1.75em',
            // transform: 'translateY(-.5em)',
          },
        },
        '&.MuiTooltip-tooltipPlacementRight .MuiTooltip-arrow': {
          width: '10px',
          height: '20px',
          right: '100%',
          top: 0,
          bottom: 0,
          margin: 'auto',
          '&::before': {
            transform: 'translateY(0px)',
            borderRight: '10px solid currentColor',
            borderBottom: '10px solid transparent !important',
            borderTop: '10px solid transparent !important',
            // borderWidth: '1.75em 1.75em 1.75em 0',
            // transform: 'translateY(-.5em)',
          },
        },
      },
    },
    arrow: {
      '&.public': {
        color: '#C35B44',
      },
    },
  },
  MuiCheckbox: {
    root: {
      height: '28px',
      width: '28px',
      padding: '0',
      boxShadow: 'none !important',
      border: 'none !important',
      borderRadius: '4px',
      position: 'relative',
      overflow: 'hidden',
      '&:before': {
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        border: `3px solid ${text.primary}`,
        borderRadius: '4px',
        transition: 'all .2s ease-in-out',
      },
      '& svg': {
        height: 'calc(100% + 4px)',
        width: 'calc(100% + 4px)',
      },
      '&.MuiIconButton-colorPrimary': {
        backgroundColor: `white !important`,
        color: `white !important`,
        '&:hover': {
          backgroundColor: `white !important`,
          color: `white !important`,
          '&:before': {
            borderColor: palette.primary.main,
          },
        },
        '&$checked': {
          backgroundColor: `${palette.primary.main} !important`,
          '&:before': {
            borderColor: palette.primary.main,
          },
        },
      },
    },
    colorPrimary: {},
  },
  MuiAutocomplete: {
    root: {
      zIndex: zIndex.dropdown,
      position: 'relative',
      '& .MuiInputBase-root': {
        transition: mixins.transition(['box-shadow']),
      },
      '& .MuiOutlinedInput-notchedOutline': {
        borderWidth: '3px !important',
        borderColor: 'transparent !important',
        transition: mixins.transition(['border-width', 'border-color']),
      },
      '&[aria-expanded="true"]': {
        zIndex: zIndex.max,
        '& .MuiInputBase-root': {
          borderRadius: 0,
          // boxShadow: '0 2px 9px 0 rgba(0, 0, 0, .32)',
        },
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: `${palette.primary.main} !important`,
          // borderWidth: '3px !important',
        },
      },
    },
    popupIndicator: {
      display: 'none',
    },
    inputRoot: {
      zIndex: zIndex.primary,
      ...typography.input,
      position: 'relative',
      paddingLeft: '40px !important',
      paddingRight: '10px !important',
      '&[class*="MuiOutlinedInput-root"]': {
        // padding: 0,
        paddingLeft: 10,
      },
      '&:before': {
        content: '""',
        height: '24px',
        width: '24px',
        // backgroundImage: `url(${iconSearch})`,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        position: 'absolute',
        left: 10,
      },
    },
    tag: {
      borderRadius: 4,
      ...typography.h5,
      '& svg': {
        color: text.primary,
        opacity: 0.5,
        transition: mixins.transition(['fill', 'opacity', 'stroke']),
        '&:hover': {
          opacity: 1,
        },
      },
    },
    endAdornment: {
      display: 'none',
    },
    option: {
      ...typography.h5,
      position: 'relative',
      color: text.secondaryHueLight,
      paddingLeft: 40,
      backgroundColor: `${palette.common.white} !important`,
      '& > div': {
        position: 'relative',
        // borderBottom: '1px solid transparent',
        transition: mixins.transition(['border-color', 'transform', 'background-color']),
        '&:before': {
          content: '"+"',
          fontWeight: 800,
          position: 'absolute',
          left: '-13px',
          opacity: 0,
          transition: mixins.transition(['opacity']),
        },
      },
      '&:hover, &[data-focus="true"]': {
        backgroundColor: `rgba(3, 54, 145, .08) !important`,
        '& > div': {
          // borderColor: 'currentColor',
          transform: 'translateX(13px)',
          '&:before': {
            opacity: 1,
          },
        },
      },
      '& .selected': {
        display: 'none',
      },
    },
    paper: {
      transform: 'translateY(-10px)',
      boxShadow: '0 2px 9px 0 rgba(0, 0, 0, .32)',
    },
    popper: {
      zIndex: zIndex.primary,
      maxWidth: '100%',
      marginLeft: -4,
      // paddingLeft: '12px',
      [`@media(min-width: ${breakpoints.md}px)`]: {
        paddingLeft: '14px',
        maxWidth: '500px',
      },
      [`@media(min-width: ${breakpoints.lg}px)`]: {
        paddingLeft: '0px',
        marginLeft: 0,
      },
      // right: 'auto !important',
      // left: '0 !important',
    },
    noOptions: {
      ...typography.subtitle,
      color: text.tertiary,
      textAlign: 'center',
      paddingTop: 30,
      paddingBottom: 30,
    },
    listbox: {
      paddingTop: 20,
      paddingBottom: 20,
    },
  },
  MuiButton: {
    root: {
      boxShadow: 'none !important',
    },
    contained: {
      borderRadius: 30,
      minHeight: 36,
      minWidth: 130,
      '&$disabled': {
        backgroundColor: palette.status.disabled,
        color: palette.white,
        cursor: 'not-allowed',
      },
    },
    outlined: {
      borderRadius: 30,
      minHeight: 36,
      minWidth: 130,
      // color: palette.gray.outlinedButton,
      '&$disabled': {
        backgroundColor: palette.status.disabled,
        color: palette.white,
        cursor: 'not-allowed',
      },
    },
  },
  MuiInput: {
    root: {
      // backgroundColor: palette.gray.inputBackground,
      backgroundColor: '#FAFAFA',
      padding: '15px 10px',
      borderRadius: '6px',
      position: 'relative',
      overflow: 'hidden',
      '&:before': {
        display: 'none',
      },
      '&:after': {
        content: '""',
        borderBottom: 'none',
        borderRight: `0px solid ${palette.status.success}`,
        position: 'absolute',
        left: 'auto !important',
        right: 0,
        top: 0,
        bottom: 0,
        height: '100%',
        width: 0,
        transform: 'none !important',
        transition: 'all .15s ease-in-out !important',
      },
      '&$error': {
        '&:after': {
          borderRightWidth: '8px',
          borderRightColor: palette.status.error,
        },
      },
    },
  },
}

export default overrides
